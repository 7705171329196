import {
  FALLBACK_MESSAGE, TIME_ZONE, getValue, isFalsyString, sentenceCase,
} from '@emobg/web-utils';
import { DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../Vehicles/VehicleDamages/damages.const';

export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'rangeDateTimePicker',
    props: {
      title: 'Date',
      attributeName: 'reporting_timestamp',
      timezone: getValue(this, 'operator.timezone', TIME_ZONE.default),
      labels: {
        from: 'From',
        to: 'To',
        removeButton: 'Clear dates',
      },

    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'vehicle.cs_operator_name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'vehicle.city',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Vehicle side',
      attributeName: 'side',
      transformValue: side => DAMAGE_BLUEPRINT_SIDE_NAMES[side].label,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Damage status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Investigation status',
      attributeName: 'investigation.status',
      transformValue: value => isFalsyString(value) ? FALLBACK_MESSAGE.dash : sentenceCase(value),
    },
  },
];
